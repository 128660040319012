import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  useAssignPlaylistMutation,
  useGetUnassignedPlaylistsQuery,
  FILTERS,
} from "services/playlists";

import withSelectCheckbox from "HOCs/withSelectCheckbox";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";

import CommonTemplate from "templates/CommonTemplate";

import Title from "components/Title";
import Section from "components/Section";
import Playlist from "components/Playlist";
import SearchInput from "components/SearchInput";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import Button from "ui/Button";
import Skeleton from "ui/Skeleton";
import Chip from "ui/Chip";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const SelectPlaylist = withSelectCheckbox(Playlist);

const UnassignedPlaylists = () => {
  const { screenId } = useParams();
  const navigate = useNavigate();

  const [assignPlaylist] = useAssignPlaylistMutation();

  const [selectedPlaylists, setSelectedPlaylists] = useState([]);

  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch("");
  const debouncedSearch = useDebounce(700);

  const {
    data: { data: playlistsData = [], total = 0 } = {},
    isLoading: isPlaylistsLoading,
    isFetching: isFetchingPlaylists,
  } = useGetUnassignedPlaylistsQuery({
    page,
    limit: PAGINATION.limit,
    search,
    screenId,
    filter: FILTERS.UNASSIGNED,
  });

  const countOfPlaylists = total ?? playlistsData.length;
  const countOfSelectedPlaylists = selectedPlaylists.length;

  const handleSelect = (playlistId) => {
    if (selectedPlaylists.includes(playlistId)) {
      setSelectedPlaylists(selectedPlaylists.filter((id) => id !== playlistId));
    } else {
      setSelectedPlaylists((prevPlaylists) => [...prevPlaylists, playlistId]);
    }
  };

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  const onSubmit = async () => {
    try {
      const promises = selectedPlaylists.map((playlistId) =>
        assignPlaylist({ playlistId, deviceId: screenId })
      );

      await Promise.all(promises);
      navigate(-1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <CommonTemplate headerProps={{ title: "Assign to Screen" }}>
      <div className="flex flex-1 flex-col gap-4">
        {/*label SEARCH*/}
        <SearchInput.Fixed
          fullWidth
          placeholder="Search Playlist"
          defaultValue={search}
          onChange={({ target }) => {
            debouncedSearch(() => {
              setPage(1);
              setSearch(target.value);
            });
          }}
        />

        {/*label PLAYLISTS LIST*/}
        <Section
          title="Playlists"
          titleSection={(title) => (
            <Title size="2xl">
              {isFetchingPlaylists ? (
                <Skeleton width={130} />
              ) : (
                <>
                  {title}{" "}
                  <span className="text-base-500">{`(${countOfPlaylists})`}</span>
                </>
              )}
            </Title>
          )}
          actionsSection={
            <Chip
              label={`${countOfSelectedPlaylists} selected`}
              color="secondary"
              size="small"
            />
          }
          className="px-4 py-3"
        >
          <InfiniteScroll
            next={handleMoreData}
            dataLength={playlistsData.length}
            hasMore={page * PAGINATION.limit < total}
            scrollThreshold={0.55}
            loader={
              isFetchingPlaylists && (
                <div className="mt-4">
                  <SkeletonWrapper condition Component={Playlist.Skeleton} />
                </div>
              )
            }
          >
            <div className="flex flex-col gap-4">
              <ComponentFallbackRenderer
                isLoading={isFetchingPlaylists}
                data={playlistsData}
              >
                {(playlists) =>
                  (isFetchingPlaylists && !playlists.length
                    ? Array.from({ length: 4 }, () => ({ uuid: uuidv4() }))
                    : playlists
                  ).map(
                    ({
                      uuid,
                      id = uuid,
                      isDefault,
                      name,
                      previews = [],
                      videoUrl,
                    }) => {
                      const indexOf = selectedPlaylists.indexOf(id);
                      const isChecked = indexOf >= 0;
                      const orderNumber = isChecked ? indexOf + 1 : null;

                      return (
                        <SkeletonWrapper
                          key={id}
                          condition={isFetchingPlaylists && !playlists.length}
                          Component={Playlist.Skeleton}
                        >
                          <SelectPlaylist
                            isShowCheckbox
                            name={name}
                            videos={previews}
                            footerRightContent={null}
                            className={
                              "bg-gradient-to-t from-black/10 to-black/10 bg-white"
                            }
                            checked={isChecked}
                            orderNumber={orderNumber}
                            onClickCheckbox={() => handleSelect(id)}
                          />
                        </SkeletonWrapper>
                      );
                    }
                  )
                }
              </ComponentFallbackRenderer>
            </div>
          </InfiniteScroll>
        </Section>
        <div className="sticky bottom-6 container px-4 bg-base-0 z-[1]">
          <Button
            variant="contained"
            component={NavLink}
            fullWidth
            onClick={onSubmit}
          >
            Add
          </Button>
        </div>
      </div>
    </CommonTemplate>
  );
};

export default UnassignedPlaylists;
