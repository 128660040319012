import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

import { hideDrawer, showDrawer } from "store/ducks/ui/drawer";

import { replaceParams } from "utils/router";
import { NOTIFICATION_MESSAGES } from "utils/notificationMessages";

import { PLAYLIST_ACTIONS } from "constants/drawer";

import toast from "HOCs/toast";

import CommonTemplate from "templates/CommonTemplate";

import Section from "components/Section";
import Playlist from "components/Playlist";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import Button from "ui/Button";
import Switch from "ui/Switch";
import IconButton from "ui/IconButton";

import {
  FILTERS,
  useChangeStatusPlaylistMutation,
  useDeletePlaylistFromDeviceMutation,
  useGetPlaylistsQuery,
  useGetUnassignedPlaylistsQuery,
} from "services/playlists";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as RenameIcon } from "assets/icons/rename.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import pages from "router/links";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const Screen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { screenId } = useParams();

  const [page, setPage] = useState(PAGINATION.page);

  const [changeStatus, { data, isLoading: isLoadingChangeStatus }] =
    useChangeStatusPlaylistMutation();

  // to go to the unassignedPlaylists page if unassigned Playlists are available
  const { data: { total: unassignedPlaylistsTotal = 0 } = {} } =
    useGetUnassignedPlaylistsQuery({
      page: 1,
      limit: 1,
      screenId,
      filter: FILTERS.UNASSIGNED,
    });

  const {
    data: { data: playlistsData = [], device_name, total } = {},
    isFetching: isPlaylistsFetching,
    refetch,
  } = useGetPlaylistsQuery({
    device: screenId,
    page,
    limit: PAGINATION.limit,
  });

  const [deletePlaylist] = useDeletePlaylistFromDeviceMutation();

  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  // set default checkbox
  useEffect(() => {
    const activePlaylist = playlistsData.find((playlist) => playlist.is_active);
    if (activePlaylist) {
      setActiveId(activePlaylist.id);
    }
  }, [playlistsData]);

  const menuItems = useMemo(
    () => [
      {
        icon: RenameIcon,
        label: "Rename",
        onClick: (e, { id }) => {
          navigate(
            replaceParams(pages.playlistEdit.path, {
              playlistId: id,
            })
          );
          dispatch(hideDrawer());
        },
      },
      {
        icon: DeleteIcon,
        label: "Delete on device",
        color: "text-error",
        onClick: (e, { id }) => {
          deletePlaylist({
            id,
            deviceId: screenId,
          })
            .unwrap()
            .then(() => {
              toast.success("Your Playlist is removed from the device!");
            })
            .finally(() => {
              dispatch(hideDrawer());
            });
        },
      },
    ],
    []
  );

  const handleChangeStatus = (playlistId, deviceId) => (e, value) => {
    setActiveId(value && playlistId);

    changeStatus({
      playlistId,
      deviceId,
      status: value,
    })
      .unwrap()
      .catch(() => {
        toast.error(NOTIFICATION_MESSAGES.ERROR);
      });
  };

  const handleOpenMenu = ({ id, name }) => {
    dispatch(
      showDrawer({
        content: PLAYLIST_ACTIONS,
        headerProps: {
          title: name,
        },
        data: {
          menuItems,
          id,
        },
      })
    );
  };

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  return (
    <CommonTemplate
      headerProps={{
        title: device_name,
      }}
    >
      <InfiniteScroll
        next={handleMoreData}
        dataLength={playlistsData.length}
        hasMore={page * PAGINATION.limit < total}
        scrollThreshold={0.55}
        loader={
          isPlaylistsFetching && (
            <div className="mt-4">
              <SkeletonWrapper condition Component={Playlist.Skeleton} />
            </div>
          )
        }
      >
        <ComponentFallbackRenderer
          data={playlistsData}
          isLoading={isPlaylistsFetching}
        >
          {(playlists) => (
            <div className="flex flex-1 flex-col justify-between gap-y-4 px-4 py-6">
              <div className="flex flex-col gap-12">
                <Section title="Device Management">
                  {(isPlaylistsFetching && !playlists.length
                    ? Array.from({ length: 5 }, () => ({ uuid: uuidv4() }))
                    : playlists
                  ).map(({ uuid, id = uuid, name, previews = [] }) => (
                    <SkeletonWrapper
                      condition={isPlaylistsFetching && !playlists.length}
                      Component={Playlist.Skeleton}
                    >
                      <Playlist
                        key={id}
                        name={name}
                        videos={previews}
                        footerRightContent={
                          <div className="flex items-center gap-3">
                            <Switch
                              checked={activeId === id}
                              onChange={handleChangeStatus(id, screenId)}
                            />
                            <IconButton
                              aria-label="more"
                              onClick={() => handleOpenMenu({ id, name })}
                            >
                              <MoreIcon />
                            </IconButton>
                          </div>
                        }
                      />
                    </SkeletonWrapper>
                  ))}
                </Section>
              </div>
            </div>
          )}
        </ComponentFallbackRenderer>
      </InfiniteScroll>
      <Button
        variant="contained"
        startIcon={<PlusIcon className="w-4" />}
        component={NavLink}
        disabled={!unassignedPlaylistsTotal}
        classes={{ root: "sticky bottom-6 mx-4 z-20" }}
        to={replaceParams(pages.playlistsUnassigned.path, { screenId })}
      >
        Add Playlists
      </Button>
    </CommonTemplate>
  );
};

export default Screen;
