import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { API_URL, SENTRY_DSN } from "./constants/APIs";
Sentry.init({
  enabled: !process.env.REACT_APP_DISABLED_SENTRY,
  dsn: SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // disable Replay for localhost
    ...(process.env.NODE_ENV === "production"
      ? [Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false })]
      : []),
    Sentry.replayCanvasIntegration(),
    Sentry.breadcrumbsIntegration({
      console: false,
      dom: true,
      history: true,
      xhr: true,
    }),
    Sentry.browserSessionIntegration(),
    // Sentry.captureConsoleIntegration({ levels: ["error"] }),
  ],
  beforeSend(event) {
    // if (event.level !== "error") {
    //   return null;
    // }

    if (event.user) {
      // delete event.user.email;
      delete event.user.phone;
    }

    return event;
  },
  maxBreadcrumbs: 50,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, new RegExp(`^${API_URL}`)],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
});
