import { useState } from "react";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";
import useDeleteParams from "hooks/useDeleteParams";

import { useGetProductsListQuery } from "services/catalog";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";

import Title from "components/Title";
import SearchInput from "components/SearchInput";
import MediaCard from "components/MediaCard";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import styles from "./styles.module.scss";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const Footer = ({ name, title, value }) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <span className="text-base-500 text-sm">{name}</span>
      <Title className="text-base-1000 line-clamp-2 overflow-hidden text-ellipsis">
        {title}
      </Title>
      <span className="text-base-1000 text-sm mt-1">{value}</span>
    </div>
  );
};

const Catalog = ({
  headerProps = {
    title: "Select a Product",
  },
  deleteParams,
  onSelect = () => {},
}) => {
  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch("");
  const debouncedSearch = useDebounce(700);
  const deleteParamsFromUrl = useDeleteParams();

  const {
    data: { data: productsData = [], total } = {},
    isLoading,
    isFetching,
  } = useGetProductsListQuery({
    page,
    limit: PAGINATION.limit,
    search,
  });

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  return (
    <CommonTemplate
      headerProps={headerProps}
      contentProps={{ className: "gap-8" }}
    >
      {/*label SEARCH*/}
      <SearchInput.Fixed
        fullWidth
        placeholder="Search by product name"
        defaultValue={search}
        onChange={({ target }) => {
          debouncedSearch(() => {
            setPage(1);
            setSearch(target.value);
          });
        }}
      />
      <div className="text-center text-base-500 px-4">
        Choose a product to add to your playlist. If you can’t find it, tap the
        (+) button to report a missing item.
      </div>
      <ComponentFallbackRenderer data={productsData} isLoading={isFetching}>
        {(products) => (
          <>
            {/*<div className="flex gap-1 py-4 -mx-4 pl-4 overflow-x-auto">*/}
            {/*  {Object.values(categories).map(({ id, label }) => (*/}
            {/*    <Chip*/}
            {/*      key={id}*/}
            {/*      label={label}*/}
            {/*      size="big"*/}
            {/*      variant={`tag${id !== selectedCategory ? "-outlined" : ""}`}*/}
            {/*      color={id !== selectedCategory ? "default" : "primary"}*/}
            {/*      clickable*/}
            {/*      onClick={() => setSelectedCategory(id)}*/}
            {/*    />*/}
            {/*  ))}*/}
            {/*</div>*/}
            <div className="flex flex-col px-4 py-6">
              {/*<div className="py-4">*/}
              {/*  <Button*/}
              {/*    variant="contained"*/}
              {/*    startIcon={<FilterIcon className="w-4 h-4" />}*/}
              {/*    size="small"*/}
              {/*    className="px-4 py-2"*/}
              {/*  >*/}
              {/*    Filter <span className="text-base-0/[.4] ml-1">(3)</span>*/}
              {/*  </Button>*/}
              {/*</div>*/}
              <InfiniteScroll
                next={handleMoreData}
                dataLength={products.length}
                hasMore={page * PAGINATION.limit < total}
                scrollThreshold={0.55}
                loader={null}
              >
                <div className="grid grid-cols-2 gap-x-2 gap-y-4 ">
                  {(isFetching && !products.length
                    ? Array.from({ length: 6 }, () => ({ uuid: uuidv4() }))
                    : products
                  ).map(
                    ({
                      uuid,
                      productId,
                      img,
                      preview,
                      brand,
                      name,
                      displayName,
                      variant,
                      bsImage,
                      category,
                      ...productProps
                    } = {}) => (
                      <SkeletonWrapper
                        key={uuid || productId}
                        condition={isFetching && !products.length}
                        Component={MediaCard.Skeleton}
                        skeletonProps={{ aspectRatio: "13 / 17" }}
                      >
                        <MediaCard
                          key={productId}
                          img={img}
                          className={cx(styles.aspect, "items-start")}
                          imageWrapperClassName="rounded-lg w-full h-full py-5 justify-center items-center"
                          objectContain
                          component={Button}
                          componentProps={{
                            onClick: (e) => {
                              // todo
                              deleteParamsFromUrl(deleteParams);
                              onSelect(e, {
                                productId,
                                // todo add Preview Img to CATALOG service
                                img: bsImage?.cdnUrl,
                                preview,
                                brand,
                                name,
                                displayName,
                                variant,
                                category: category?.name,
                                ...productProps,
                              });
                            },
                          }}
                          Description={
                            <Footer
                              name={brand?.name}
                              title={displayName ?? name}
                              value={variant}
                            />
                          }
                        />
                      </SkeletonWrapper>
                    )
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </>
        )}
      </ComponentFallbackRenderer>
    </CommonTemplate>
  );
};

export default Catalog;
