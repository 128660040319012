import { ToastContainer, Slide } from "react-toastify";

import Drawer from "components/Global/Drawer";
import Dialog from "components/Global/Dialog";

const AbsoluteComponents = () => {
  return (
    <>
      <Drawer />
      <Dialog />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default AbsoluteComponents;
