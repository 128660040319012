import { useState, useEffect, useRef, useCallback } from "react";
import cx from "classnames";
import { twMerge } from "tailwind-merge";

import { ASPECT_RATIO } from "constants/styles";

import useLongPress from "hooks/useLongPress";

import Title from "components/Title";

import IconButton from "ui/IconButton";
import Skeleton from "ui/Skeleton";

import { renderComponent } from "utils/components";
import { msToTimeFormat } from "utils/date";

import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder.svg";

import styles from "./styles.module.scss";

const PrimaryCard = ({
  img,
  title,
  folder,
  date,
  duration,
  durationFormatter = msToTimeFormat,
  className,
  imageClassName,
  imageWrapperClassName,
  objectContain = false,
  aspectRatio = "9/10",
  isActive = false,
  onClickButtonMore = () => {},
  showMoreBtn = false,
  isLongPress: isLongPressProps = false,
  Description,
  actionButtonIcon: ActionButtonIcon = MoreIcon,
  playerProps = {
    withPlayer: false,
    url: null,
  },
  component: Component = "div",
  componentProps = {},
}) => {
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const {
    onClick = () => {},
    onLongPress = () => {},
    ...restComponentProps
  } = componentProps;
  const { withPlayer, url: videoUrl } = playerProps;

  const { handlers: handlersLongPress, isLongPress } = useLongPress({
    onLongPress,
    delay: 500,
    disabled: !isLongPressProps,
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      const video = videoRef.current;

      if (!document.fullscreenElement) {
        // Avoid calling pause if the video is already paused
        if (!video.paused) {
          video.pause();
        }
        video.currentTime = 0;
        video.classList.remove("flex");
        video.classList.add("hidden");
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);
  const openFullScreen = useCallback(() => {
    if (!withPlayer || isLongPress) {
      return;
    }

    const video = videoRef.current;

    // Download video if it has not already been uploaded
    if (!isVideoLoaded) {
      video.innerHTML = `<source src="${videoUrl}" type="video/mp4" />`;
      video.load(); // Explicitly load the video resource
      setIsVideoLoaded(true);
    }

    video.classList.remove("hidden");
    video.classList.add("flex");

    // Full Screen Request
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      // Firefox
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      // IE/Edge
      video.msRequestFullscreen();
    }

    // Call play () with error handling
    const playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          console.log("Video playback started successfully.");
        })
        .catch((error) => {
          console.error("Error during video playback:", error);
        });
    }
  }, [isLongPress, isVideoLoaded, videoUrl, withPlayer]);

  const handleClick = () => {
    if (!withPlayer) {
      onClick();
      return;
    }
    openFullScreen();
  };

  return (
    <Component
      className={cx("flex flex-col gap-3 items-start", className)}
      onClick={handleClick}
      {...handlersLongPress}
      {...restComponentProps}
    >
      <div
        className={cx(
          "flex flex-1 card-img relative rounded-lg w-full overflow-hidden basis-full",
          imageWrapperClassName,
          {
            [styles.active]: isActive,
          }
        )}
      >
        <img
          src={img}
          alt={title}
          // hack with aspect ratio for tailwind
          className={twMerge(
            cx(
              `w-full relative text-base-1000 select-none pointer-events-none`,
              imageClassName,
              {
                "object-cover": !objectContain,
                "object-contain": objectContain,
                "aspect-[9/10]": aspectRatio === ASPECT_RATIO["9/10"],
                "aspect-[3/4]": aspectRatio === ASPECT_RATIO["3/4"],
                "aspect-[4/5]": aspectRatio === ASPECT_RATIO["4/5"],
              }
            )
          )}
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
          onTouchMove={(e) => e.preventDefault()}
        />
        {withPlayer && (
          <video
            ref={videoRef}
            controls
            controlsList="nodownload"
            className="absolute hidden -z-10 rounded-lg"
          >
            Your browser does not support the video tag.
          </video>
        )}
        {showMoreBtn && (
          <IconButton
            aria-label="info"
            className="absolute top-0 right-0 text-base-0 w-6 h-6 box-content"
            onClick={(e) => {
              e.stopPropagation();
              onClickButtonMore(e);
            }}
          >
            <ActionButtonIcon />
          </IconButton>
        )}
        {!!duration && (
          <span className="absolute right-2 bottom-2 leading-none text-base-0 text-sm px-3 py-[5px] backdrop-blur-lg bg-base-500/[.5] rounded-3xl">
            {durationFormatter(duration)}
          </span>
        )}
      </div>

      <div className="card-content flex flex-1 flex-col gap-1 text-left max-w-full">
        {title && (
          <Title className="leading-5 text-base-1000 line-clamp-2">
            {title}
          </Title>
        )}
        {renderComponent(Description) ||
          ((folder || date) && (
            <div className="flex items-center leading-none text-sm text-base-500">
              {folder && (
                <div className="flex gap-1.5 items-center">
                  <FolderIcon className="w-3.5" />
                  <span>{folder}</span>
                </div>
              )}
              {folder && date && (
                <div className="mx-[5px] w-1 h-1 bg-base-500 rounded-full" />
              )}
              {/*todo replace with date util*/}
              {date && <span>Feb 18</span>}
            </div>
          ))}
      </div>
    </Component>
  );
};

PrimaryCard.Skeleton = ({ aspectRatio = "9 / 16" }) => (
  <div className="flex flex-col gap-3">
    <Skeleton.Round
      sx={{
        aspectRatio,
        width: "100%",
        height: "auto",
      }}
    />
    <div className="flex flex-1 flex-col gap-1 text-left">
      <Skeleton width={90} sx={{ fontSize: "0.875rem" }} />
      <Skeleton width={130} sx={{ fontSize: "1rem" }} />
    </div>
  </div>
);

export default PrimaryCard;
