import { forwardRef } from "react";
import cx from "classnames";

import NumberedCheckbox from "components/NumberedCheckbox";

const withSelectCheckbox = (Component) => {
  return forwardRef((props, ref) => {
    const {
      isShowCheckbox = false,
      checked,
      orderNumber,
      onClickCheckbox,
      className,
      ...restProps
    } = props;

    return (
      <div className="relative">
        {isShowCheckbox && (
          <NumberedCheckbox
            checked={checked}
            orderNumber={orderNumber}
            onClick={onClickCheckbox}
            color={"secondary"}
            className="absolute top-1 left-1 z-[1]"
          />
        )}

        <Component
          {...restProps}
          className={cx(className, { "opacity-40": !checked })}
        />
      </div>
    );
  });
};

export default withSelectCheckbox;
