import "./sentry";
import React from "react";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Package from "./../package.json";

console.log(
  `%c v${Package.version} `,
  "color: white; background: #009688; padding: 4px; border-radius: 4px;"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log("New SW found, activating update...");

    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });

      registration.waiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          console.log("New SW activated, reloading...");
          window.location.reload();
        }
      });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
