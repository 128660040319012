import { api } from "services/api";

const BASE_URL = "catalog";

export const catalogApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductsList: build.query({
      query: ({ page = 1, limit = 10, search, field, value } = {}) => {
        return {
          url: `${BASE_URL}/search`,
          params: { page, limit, search },
        };
      },
      transformResponse: ({ data, ...response }) => ({
        data: data.map((item) => ({
          ...item,
          img: item?.previewImage?.cdnUrl || item?.bsImage?.cdnUrl,
          preview: item?.previewImage?.cdnUrl || item?.bsImage?.cdnUrl,
          displayName: item?.displayName || item?.name,
          name: item?.name || item?.displayName,
        })),
        ...response,
      }),
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          currentCache.data = newItems.data;
        } else {
          currentCache.data.push(...newItems.data);
        }
        currentCache.page = newItems.page;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
    }),
    getProductByBarcode: build.query({
      query: (barcode) => ({
        url: `${BASE_URL}/${barcode}`,
      }),
      transformResponse: (response) => {
        if (response === undefined || response === null) {
          return response;
        }

        return {
          ...response,
          img: response?.previewImage?.cdnUrl || response?.bsImage?.cdnUrl,
          preview: response?.previewImage?.cdnUrl || response?.bsImage?.cdnUrl,
          displayName: response?.displayName || response?.name,
          name: response?.name || response?.displayName,
          category: response?.category?.name,
        };
      },
    }),
    createNewProduct: build.mutation({
      query: (data) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (key !== "photo") {
            formData.append(key, data[key]);
          }
        });

        if (data.photo) {
          formData.append("photo", data.photo);
        }

        return {
          url: `${BASE_URL}/missing-product`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useGetProductsListQuery,
  useLazyGetProductByBarcodeQuery,
  useCreateNewProductMutation,
} = catalogApi;
