import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

import { useGetPlaylistsQuery } from "services/playlists";
import { useAssignToPlaylistMutation } from "services/ads";

import { hideDrawer } from "store/ducks/ui/drawer";

import { NOTIFICATION_MESSAGES } from "utils/notificationMessages";

import toast from "HOCs/toast";

import Playlist from "components/Playlist";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import Button from "ui/Button";

import pages from "router/links";

const PAGINATION = {
  limit: 13,
  page: 1,
};

const AddToPlaylistForm = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(PAGINATION.page);

  const {
    data: { data: playlistsData = [], total = 0 } = {},
    isFetching: isFetchingGetPlaylists,
  } = useGetPlaylistsQuery({
    page,
    limit: PAGINATION.limit,
  });
  const [assignToPlaylist, { isSuccess }] = useAssignToPlaylistMutation();

  const [loadingPlaylistIds, setLoadingPlaylistIds] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setLoadingPlaylistIds([]);
      dispatch(hideDrawer());
      navigate({
        pathname: pages.playlists.path,
      });
    }
  }, [dispatch, isSuccess, navigate]);

  const handleAssignToPlaylist = (id) => {
    setLoadingPlaylistIds((prev) => [...prev, id]);
    assignToPlaylist({
      ads: data.selectedAds,
      playlists: [id],
    })
      .unwrap()
      .then(() => {
        toast.success("Playlist created successfully!");
      })
      .catch(() => {
        toast.error(NOTIFICATION_MESSAGES.ERROR);
      })
      .finally(() => {
        setLoadingPlaylistIds((prev) =>
          prev.filter((playlistId) => playlistId !== id)
        );
      });
  };

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  return (
    <InfiniteScroll
      next={handleMoreData}
      dataLength={playlistsData.length}
      hasMore={page * PAGINATION.limit < total}
      scrollThreshold={0.55}
      scrollableTarget="scrollable"
      loader={
        isFetchingGetPlaylists && (
          <div className="grid grid-cols-3 gap-4 pb-4">
            {Array.from({ length: 3 }, () => ({ id: uuidv4() })).map(
              ({ id }) => (
                <SkeletonWrapper
                  key={id}
                  condition
                  Component={Playlist.Mini.Skeleton}
                />
              )
            )}
          </div>
        )
      }
    >
      <ComponentFallbackRenderer
        data={playlistsData.filter(({ isDefault }) => !isDefault)}
        isLoading={isFetchingGetPlaylists}
      >
        {(playlists) => (
          <div className="grid grid-cols-3 gap-4 pb-4">
            {(isFetchingGetPlaylists && !playlists.length
              ? Array.from({ length: 6 }, () => ({ uuid: uuidv4() }))
              : playlists
            ).map(({ uuid, id = uuid, name, previews }) => (
              <SkeletonWrapper
                key={id}
                condition={isFetchingGetPlaylists && !playlists.length}
                Component={Playlist.Mini.Skeleton}
              >
                <Playlist.Mini
                  id={id}
                  key={id}
                  name={name}
                  videos={previews}
                  component={Button}
                  loading={loadingPlaylistIds.includes(id)}
                  componentProps={{
                    onClick: (_, { id }) => handleAssignToPlaylist(id),
                  }}
                />
              </SkeletonWrapper>
            ))}
          </div>
        )}
      </ComponentFallbackRenderer>
    </InfiniteScroll>
  );
};

export default AddToPlaylistForm;
