import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import toast from "HOCs/toast";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

import { useGetTemplatesQuery } from "services/templates";
import {
  useCreateDefaultPlaylistMutation,
  useApprovePlaylistMutation,
} from "services/playlists";

import IconButton from "ui/IconButton";

// label PAGES
import ListSelectedProducts from "./components/ListSelectedProducts";

import VideoPlayer from "pages/PlaylistCreate/pages/VideoPlayer";
import TemplatesCatalog from "pages/TemplatesCatalog";
import ProductCatalog from "pages/SelectProduct";
import AssignScreens from "pages/AssignScreens";
import Loader from "components/Loader";

import { PRODUCT_VIEWS } from "constants/index";
import { VIEW_MODE } from "constants/searchParams";

import { getComponent } from "utils/components";
import { getDefaultPlaylistFields } from "utils/templates";
import { NOTIFICATION_MESSAGES } from "utils/notificationMessages";

import pages from "router/links";

import { ENTITIES } from "components/Loader/helpers";
import { STEPS } from "./helpers";
import { schema } from "./schema";

const STEPS_MAP = {
  default: ListSelectedProducts,
  [STEPS.LIST_SELECTED_PRODUCTS]: ListSelectedProducts,
  [STEPS.PRODUCTS_CATALOG]: ProductCatalog,
  [STEPS.TEMPLATES_CATALOG]: TemplatesCatalog,
  [STEPS.VIDEO_PLAYER]: VideoPlayer,
  [STEPS.ASSIGN_SCREENS]: AssignScreens,
  [STEPS.LOADER]: Loader,
};

const Default = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get(VIEW_MODE);
  const defaultStep =
    view === PRODUCT_VIEWS.SCANNER || view === PRODUCT_VIEWS.CATALOG
      ? STEPS.PRODUCTS_CATALOG
      : STEPS.LIST_SELECTED_PRODUCTS;

  const [step, setStep] = useState(defaultStep);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const Component = getComponent(STEPS_MAP, step);

  // label CREATE PLAYLIST
  const [
    createDefaultPlaylist,
    { isLoading: isLoadingCreatePlaylist, data: { data: createData } = {} },
  ] = useCreateDefaultPlaylistMutation();

  // label APPROVE PLAYLIST
  const [
    approvePlaylist,
    {
      data: approveData,
      isLoading: isLoadingApprovePlaylist,
      isSuccess: isSuccessApprovePlaylist,
    },
  ] = useApprovePlaylistMutation();

  // get the first template and set it as default
  const {
    data: { data: templatesData = [] } = {},
    isLoading: isTemplatesLoading,
  } = useGetTemplatesQuery({
    // categories: [allCategories.join(",")],
    limit: 1,
    useDefault: true,
  });

  useEffect(() => {
    if (!!templatesData.length) {
      const { id, name, preview_url } = templatesData[0];

      setSelectedTemplate({
        id,
        name,
        img: preview_url,
      });
    }
  }, [templatesData]);

  // label CREATE PLAYLIST
  useEffect(() => {
    if (isLoadingCreatePlaylist) {
      setStep(STEPS.LOADER);
    }
  }, [isLoadingCreatePlaylist]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      products: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const handleRemove = (index) => {
    remove(index);
  };

  const handleSelectTemplate = (e, { id, title, img }) => {
    setSelectedTemplate({
      id,
      name: title,
      img,
    });
    setStep(STEPS.LIST_SELECTED_PRODUCTS);
  };

  const handleSelectProduct = (
    e,
    { brand, img, bsImage, displayName, variant, category, previewImage }
  ) => {
    append({
      // todo fix img
      preview: previewImage?.cdnUrl ?? img,
      img: bsImage?.cdnUrl ?? img,
      brand: brand?.name,
      name: displayName,
      variant,
      category,
    });
    setStep(STEPS.LIST_SELECTED_PRODUCTS);
  };

  const getComponentProps = useCallback(
    (step) => {
      let props = {};
      switch (step) {
        default:
        case STEPS.LIST_SELECTED_PRODUCTS:
          props = {
            headerProps: {
              title: "Playlist Creation",
            },
            onChangeStep: setStep,
            onScan: handleOpenProductsCatalog(PRODUCT_VIEWS.SCANNER),
            onAdd: handleOpenProductsCatalog(PRODUCT_VIEWS.CATALOG),
            onDelete: handleRemove,
            onSubmit: handleSubmit(({ products }) => {
              const data = getDefaultPlaylistFields(
                products,
                selectedTemplate.id
              );

              createDefaultPlaylist(data)
                .unwrap()
                .then(() => setStep(STEPS.VIDEO_PLAYER))
                .catch(() => setStep(STEPS.LIST_SELECTED_PRODUCTS));
            }),
            template: selectedTemplate,
            isLoading: isTemplatesLoading,
            ...{
              control,
              fields,
              register,
              errors,
            },
          };
          break;

        case STEPS.VIDEO_PLAYER:
          props = {
            headerProps: {
              title: "Instant Playlist",
              description: "Instant Playlist Preview",
              onBack: () => {
                setStep(STEPS.LIST_SELECTED_PRODUCTS);
              },
            },
            videoUrl: createData?.video_url,
            isLoading: isLoadingApprovePlaylist,
            onSubmit: () => {
              approvePlaylist({ playlistId: createData?.id })
                .unwrap()
                .then(() => {
                  setStep(STEPS.ASSIGN_SCREENS);
                })
                .catch(() => {
                  toast.error(NOTIFICATION_MESSAGES.ERROR);
                });
            },
          };
          break;
        case STEPS.TEMPLATES_CATALOG:
          props = {
            headerProps: {
              title: "Select Your Default Template",
              onBack: () => {
                setStep(STEPS.LIST_SELECTED_PRODUCTS);
              },
            },
            onSelect: handleSelectTemplate,
          };
          break;
        case STEPS.PRODUCTS_CATALOG:
          props = {
            headerProps: {
              title: "Select a Product",
              onBack: () => {
                setStep(STEPS.LIST_SELECTED_PRODUCTS);
              },
              deleteParams: ["search", "view"],
              ActionSideRight: (
                <IconButton
                  aria-label="add new product"
                  variant="contained"
                  size="medium"
                  onClick={() => navigate(pages.addNewProduct.path)}
                >
                  <PlusIcon />
                </IconButton>
              ),
            },
            onSelect: handleSelectProduct,
          };
          break;
        case STEPS.ASSIGN_SCREENS:
          props = {
            headerProps: {
              title: "Screens",
              onBack: () => setStep(STEPS.VIDEO_PLAYER),
            },
            playlistId: approveData?.playlist?.id,
          };
          break;
        case STEPS.LOADER:
          props = {
            entity: ENTITIES.PLAYLIST,
          };
          break;
      }

      return props;
    },
    [
      handleSubmit,
      control,
      fields,
      register,
      errors,
      createData?.video_url,
      createData?.id,
      selectedTemplate,
      selectedTemplate.id,
      isTemplatesLoading,
      isLoadingApprovePlaylist,
      approveData?.playlist?.id,
      approvePlaylist,
      navigate,
      pages,
    ]
  );

  // todo looks like this function does not do what it should do when it was written
  const handleOpenProductsCatalog =
    (view = PRODUCT_VIEWS.CATALOG) =>
    (e, key) => {
      setStep(STEPS.PRODUCTS_CATALOG);

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(VIEW_MODE, view);
      navigate(
        {
          pathname: window.location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    };

  return <Component {...getComponentProps(step)} />;
};

export default Default;
