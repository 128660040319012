import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import * as Sentry from "@sentry/react";

import { api } from "services/api";

import { reducers } from "./index";

const isDevelopment = process.env.NODE_ENV === "development";
const isLogger = false;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => action,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    if (isDevelopment && isLogger) {
      return getDefaultMiddleware().concat(logger, api.middleware);
    }
    return getDefaultMiddleware().concat(api.middleware);
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  devTools: true,
});

export default store;
