import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import CommonTemplate from "templates/CommonTemplate";

import { useGetAdQuery, useUpdateAdMutation } from "services/ads";

import Button from "ui/Button";
import Input from "ui/Input";
import Skeleton from "ui/Skeleton";

import pages from "router/links";

import { schema } from "./schema";

const AdEdit = ({
  headerProps,
  fieldName = "name",
  submitButtonLabel = "Save",
  submitButtonProps = {},
  onClick,
}) => {
  const { adId } = useParams();
  const navigate = useNavigate();

  // todo
  const {
    data,
    isLoading: isLoadingAd,
    isFetching: isFetchingAd,
  } = useGetAdQuery(adId);
  const [updateAd, { isLoading }] = useUpdateAdMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (data?.name) {
      setValue(fieldName, data?.name);
    }
  }, [data?.name, fieldName, setValue]);

  const onSubmit = ({ name }) => {
    updateAd({ id: adId, data: { name } })
      .unwrap()
      .then(() => navigate(pages.ads.path));
  };

  return (
    <CommonTemplate headerProps={headerProps}>
      <form
        className="flex flex-1 flex-col justify-between gap-2 px-4 py-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-12">
          {isLoadingAd ? (
            <Skeleton.Round width="100%">
              <Input />
            </Skeleton.Round>
          ) : (
            <Input
              {...register(fieldName)}
              autoComplete="off"
              label="Name"
              type="text"
              helperText={errors[fieldName]?.message}
              error={!!errors[fieldName]}
            />
          )}
        </div>
        <Button.Loading
          type="submit"
          loading={isLoading}
          disabled={isFetchingAd}
          variant="contained"
          fullWidth
          classes={{ root: "sticky bottom-6" }}
        >
          {submitButtonLabel}
        </Button.Loading>
      </form>
    </CommonTemplate>
  );
};

export default AdEdit;
