import { createBrowserRouter } from "react-router-dom";
import { createRoutes, getRoutes } from "utils/router";
import * as Sentry from "@sentry/react";

import links from "router/links";

// label TEMPLATES
import BaseTemplate from "templates/BaseTemplate";

// label PAGES
import HomePage from "pages/Home";

import PlaylistsPage from "pages/Playlists";
import PlaylistCreatePage from "pages/PlaylistCreate";
import PlaylistAssignPage from "pages/AssignScreens";
import PlaylistUnassignedPage from "pages/UnassignedPlaylists";
import PlaylistEditPage from "pages/PlaylistEdit";

import TemplatesPage from "pages/Templates";
import TemplatesListPage from "pages/TemplatesList";

import AdsPage from "pages/Ads";
import AdsCreatePage from "pages/AdsCreate";
import AdEditPage from "pages/AdEdit";

import AddNewProductPage from "pages/AddNewProduct";

import StoresPage from "pages/Stores";
import StorePage from "pages/Store";

import Error404Page from "pages/404";
import LoginPage from "pages/Login";
import ProfilePage from "pages/Profile";
import ScreenPage from "pages/Screen";
import SelectProductPage from "pages/SelectProduct";

const PAGES = {
  home: {
    main: HomePage,
    template: BaseTemplate,
  },
  playlists: {
    main: PlaylistsPage,
    template: BaseTemplate,
  },
  playlistCreate: {
    main: PlaylistCreatePage,
  },
  playlistsAssign: {
    main: PlaylistAssignPage,
  },
  playlistsUnassigned: {
    main: PlaylistUnassignedPage,
  },
  playlistEdit: {
    main: PlaylistEditPage,
  },
  addNewProduct: {
    main: AddNewProductPage,
  },
  templates: {
    main: TemplatesPage,
    template: BaseTemplate,
  },
  templatesList: {
    main: TemplatesListPage,
  },
  ads: {
    main: AdsPage,
    // template: BaseTemplate, // todo Now the global template for [Ads Page] is used in the middle of the [Ads Page]
  },
  adCreate: {
    main: AdsCreatePage,
  },
  adEdit: {
    main: AdEditPage,
  },
  selectProduct: {
    main: SelectProductPage,
  },
  scanner: {
    main: SelectProductPage,
    // template: BaseTemplate,
  },
  stores: {
    main: StoresPage,
    template: BaseTemplate,
  },
  store: {
    main: StorePage,
  },
  screen: {
    main: ScreenPage,
  },
  profile: {
    main: ProfilePage,
  },
  login: {
    status: "public",
    main: LoginPage,
  },
  404: {
    main: Error404Page,
  },
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

const { routes: browserRoutes } = sentryCreateBrowserRouter(
  createRoutes(PAGES, links)
);
const routes = getRoutes(browserRoutes);

export default routes;
