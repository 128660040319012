import * as yup from "yup";

export const schema = yup.object().shape({
  products: yup.array().of(
    yup.object().shape({
      price: yup
        .number()
        .typeError("Price must be a number")
        .required("Price is required")
        .transform((value, originalValue) => {
          const normalizedValue = originalValue.replace(",", ".");
          return isNaN(normalizedValue) ? null : parseFloat(normalizedValue);
        })
        .test(
          "is-positive",
          "Price must be greater than 0",
          (value) => value > 0
        ),
      discount: yup
        .number()
        .nullable()
        .typeError("Discount must be a number")
        .transform((value, originalValue) => {
          const normalizedValue = originalValue.replace(",", ".");
          return originalValue === ""
            ? null
            : isNaN(normalizedValue)
            ? null
            : parseFloat(normalizedValue);
        }),
    })
  ),
});
