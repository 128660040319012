import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import toast from "HOCs/toast";

import { getComponent } from "utils/components";

import { useCreateNewProductMutation } from "services/catalog";

// label PAGES
import Form from "pages/AddNewProduct/pages/Form";
import Scanner from "pages/Scanner";

import { schema } from "pages/AddNewProduct/schema";

import { BARCODE_FIELD, IMAGE_FIELD, STEPS } from "./helpers";

const STEPS_MAP = {
  default: Form,
  [STEPS.FORM]: Form,
  [STEPS.SCANNER]: Scanner,
};

const AddNewProduct = ({ pageMeta }) => {
  const navigate = useNavigate();

  const [step, setStep] = useState(STEPS.FORM);

  const Component = getComponent(STEPS_MAP, step);

  const [createNewProduct, { isLoading: isLoadingCreateNewProduct }] =
    useCreateNewProductMutation();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleDrop = useCallback(
    (files) => {
      setValue(IMAGE_FIELD, files[0]);
    },
    [setValue]
  );

  const handleDelete = useCallback(() => {
    setValue(IMAGE_FIELD, null);
  }, [setValue]);

  // label COMPONENT PROPS
  const getComponentProps = useCallback(
    (step) => {
      let props = {};
      switch (step) {
        default:
        case STEPS.FORM:
          props = {
            register,
            errors,
            watch,
            getValues,
            headerProps: {
              title: pageMeta?.label,
            },
            loading: isLoadingCreateNewProduct,
            onDrop: handleDrop,
            onDelete: handleDelete,
            onScan: () => setStep(STEPS.SCANNER),
          };
          break;

        case STEPS.SCANNER:
          props = {
            onDetect: (res) => {
              setValue(BARCODE_FIELD, res);
              setStep(STEPS.FORM);
            },
            headerProps: {
              onBack: () => setStep(STEPS.FORM),
            },
          };
          break;
      }

      return props;
    },
    [
      errors,
      getValues,
      handleDelete,
      handleDrop,
      isLoadingCreateNewProduct,
      pageMeta?.label,
      register,
      setValue,
      watch,
    ]
  );

  const onSubmit = (data) => {
    createNewProduct(data)
      .unwrap()
      .then(() => {
        toast.success("Thank you for reporting a missing product!");
        navigate(-1);
      })
      .catch(() => {
        toast.error("Loading error. Please try again!");
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Component {...getComponentProps(step)} />
    </form>
  );
};

export default AddNewProduct;
