import { api } from "services/api";

const BASE_URL = "playlists";

export const FILTERS = {
  ALL: "all",
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned",
};

export const playlistsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createPlaylist: build.mutation({
      query: (data) => ({
        url: BASE_URL,
        method: "POST",
        body: data,
      }),
    }),
    createDefaultPlaylist: build.mutation({
      query: (data) => ({
        url: `${BASE_URL}/default`,
        method: "POST",
        body: {
          ads: data,
        },
      }),
    }),
    approvePlaylist: build.mutation({
      query: ({ playlistId, name = "Instant Playlist" }) => ({
        url: `${BASE_URL}/approve`,
        method: "PUT",
        body: {
          playlist_id: playlistId,
          name,
        },
      }),
      invalidatesTags: () => [{ type: "Playlists", id: "LIST" }],
    }),
    assignPlaylist: build.mutation({
      query: ({ playlistId, deviceId }) => ({
        url: `${BASE_URL}/${playlistId}/${deviceId}`,
        method: "PUT",
      }),
      invalidatesTags: () => [{ type: "Playlists", id: "UNASSIGNED_LIST" }],
    }),
    assignDevicesToPlaylist: build.mutation({
      query: ({ playlistId, data }) => ({
        url: `${BASE_URL}/assign/${playlistId}/array`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: () => [{ type: "Stores", id: "LIST" }],
      // todo
      // async onQueryStarted(
      //   { data, playlistId },
      //   { dispatch, queryFulfilled, ...p }
      // ) {
      //   try {
      //     await queryFulfilled;
      //     console.log({ playlistId, dispatch, queryFulfilled, p });
      //     toast(`Playlist assigned to Screen 1 at London Store. ${playlistId}`);
      //   } catch (error) {
      //     console.log({ error });
      //   }
      // },
    }),
    changeStatusPlaylist: build.mutation({
      query: ({ playlistId, deviceId, status = false }) => ({
        url: `${BASE_URL}/active`,
        method: "PUT",
        body: {
          playlist_id: Number(playlistId),
          device_id: Number(deviceId),
          active: status,
        },
      }),
    }),
    deletePlaylist: build.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: "DELETE",
        responseHandler: async (response) => {
          const contentType = response.headers.get("content-type");

          if (contentType && contentType.includes("application/json")) {
            return await response.json();
          } else {
            return await response.text();
          }
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        console.log(id);
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getPlaylists", {}, (draft) => {
              console.log(draft);
              draft.data = draft.data.filter((playlist) => playlist.id !== id);
              draft.total -= 1;
            })
          );
        } catch (error) {
          console.error("Error deleting:", error);
          throw error instanceof Error ? error : new Error(String(error));
        }
      },
    }),
    deletePlaylistFromDevice: build.mutation({
      query: ({ id, deviceId }) => ({
        url: `${BASE_URL}/delete-with-dewice/${deviceId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "Playlists", id: "LIST" },
        { type: "Playlists", id: "UNASSIGNED_LIST" },
      ],
    }),
    updatePlaylist: build.mutation({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Playlists", id }],
    }),
    getPlaylists: build.query({
      query: ({ page = 1, limit = 10, search = "", device } = {}) => {
        const params = { page, limit, search };

        // if (device !== undefined) {
        //   params.device = device;
        // }

        return {
          url: BASE_URL,
          params: {
            page,
            limit,
            search,
            device,
          },
        };
      },
      // keepUnusedDataFor: 0,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1 || !arg.page) {
          Object.assign(currentCache, newItems);
        } else {
          const existingIds = new Set(currentCache.data.map((item) => item.id));
          const filteredNewItems = newItems.data.filter(
            (item) => !existingIds.has(item.id)
          );

          Object.assign(currentCache, {
            ...newItems,
            data: [...(currentCache.data || []), ...filteredNewItems],
          });
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
      transformResponse: ({ data, ...response }) => {
        return {
          data: data.map(
            ({ is_default, is_draft, video_url, ...playlist }) => ({
              isDefault: is_default,
              isDraft: is_draft,
              videoUrl: video_url,
              ...playlist,
            })
          ),
          ...response,
        };
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Playlists", id })),
              { type: "Playlists", id: "LIST" },
            ]
          : [{ type: "Playlists", id: "LIST" }],
    }),
    getUnassignedPlaylists: build.query({
      query: ({
        page = 1,
        limit = 10,
        search = "",
        screenId,
        filter = FILTERS.ALL,
      } = {}) => {
        return {
          url: `${BASE_URL}/list/${screenId}/${filter}`,
          params: {
            page,
            limit,
            search,
          },
        };
      },
      keepUnusedDataFor: 0,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1 || !arg.page) {
          Object.assign(currentCache, newItems);
        } else {
          Object.assign(currentCache, newItems, {
            data: [...(currentCache.data || []), ...newItems.data],
          });
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page ||
          currentArg.limit !== previousArg?.limit
        );
      },
      transformResponse: ({ playlists, ...response }) => {
        return {
          data: playlists.map(
            ({ is_default, is_draft, video_url, ...playlist }) => ({
              isDefault: is_default,
              isDraft: is_draft,
              videoUrl: video_url,
              ...playlist,
            })
          ),
          ...response,
        };
      },
      providesTags: [{ type: "Playlists", id: "UNASSIGNED_LIST" }],
    }),
    getPlaylist: build.query({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Playlists", id }],
    }),
    getPlaylistProgress: build.query({
      query: () => ({
        url: `${BASE_URL}/status/process`,
      }),
    }),
  }),
});

export const {
  useCreatePlaylistMutation,
  useCreateDefaultPlaylistMutation,
  useApprovePlaylistMutation,
  useAssignPlaylistMutation,
  useAssignDevicesToPlaylistMutation,
  useChangeStatusPlaylistMutation,
  useDeletePlaylistMutation,
  useDeletePlaylistFromDeviceMutation,
  useGetPlaylistsQuery,
  useGetUnassignedPlaylistsQuery,
  useGetPlaylistQuery,
  useUpdatePlaylistMutation,
  useGetPlaylistProgressQuery,
  // useGetPlaylistQuery,
  // useGetPlaylistsByCategoryQuery,
} = playlistsApi;
