import { api } from "services/api";

const BASE_URL = "ad";

export const adsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAd: build.mutation({
      query: ({ name, templateId, data }) => ({
        url: BASE_URL,
        method: "POST",
        body: { name, template_id: Number(templateId), data },
      }),
    }),
    deleteAd: build.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: "DELETE",
      }),
      // invalidatesTags: (result, error, id) =>
      //   console.log(result, error, id) || [
      //     { type: "Ads", id: "LIST" },
      //     { type: "Ads", id },
      //   ],
    }),
    deleteAds: build.mutation({
      query: (ids) => ({
        url: BASE_URL,
        method: "DELETE",
        body: { ids },
        responseHandler: async (response) => {
          const contentType = response.headers.get("content-type");

          if (contentType && contentType.includes("application/json")) {
            return await response.json();
          } else {
            return await response.text();
          }
        },
      }),
      async onQueryStarted(ids, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getAds", {}, (draft) => {
              draft.data = draft.data.filter((item) => !ids.includes(item.id));
              draft.total -= ids.length;
            })
          );
        } catch (error) {
          console.error("Error deleting:", error);
          throw error instanceof Error ? error : new Error(String(error));
        }
      },
      // invalidatesTags: (result, error, ids) =>
      //   ids.length
      //     ? [
      //         ...ids.map((id) => ({ type: "Ads", id })),
      //         // { type: "Ads", id: "LIST" },
      //       ]
      //     : [{ type: "Ads", id: "LIST" }],
    }),
    createAdFromImage: build.mutation({
      query: (data) => ({
        url: `${BASE_URL}/file`,
        method: "POST",
        body: data,
      }),
    }),
    assignToPlaylist: build.mutation({
      query: ({ ads, playlists }) => ({
        url: `${BASE_URL}/playlist`,
        method: "PUT",
        body: {
          ads,
          playlists,
        },
      }),
    }),
    getAds: build.query({
      query: ({ page = 1, limit = 10, search } = {}) => ({
        url: BASE_URL,
        params: { page, limit, search },
      }),
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1 || !arg.page) {
          Object.assign(currentCache, newItems);
        } else {
          const existingIds = new Set(currentCache.data.map((item) => item.id));
          const filteredNewItems = newItems.data.filter(
            (item) => !existingIds.has(item.id)
          );

          Object.assign(currentCache, {
            ...newItems,
            data: [...(currentCache.data || []), ...filteredNewItems],
          });
        }
      },

      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
      transformResponse: (response) => {
        const { data = [], ...rest } = response;

        return {
          ...rest,
          data: data.map(
            ({
              id,
              name,
              duration,
              customer_id,
              min_preview_url,
              preview_url,
              video_url,
              template_id,
              slots,
            }) => ({
              id,
              name,
              duration,
              customerId: customer_id,
              minPreviewUrl: min_preview_url,
              previewUrl: preview_url,
              videoUrl: video_url,
              templateId: template_id,
              slots,
            })
          ),
        };
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Ads", id })),
              { type: "Ads", id: "LIST" },
            ]
          : [{ type: "Ads", id: "LIST" }],
    }),
    getAd: build.query({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Ads", id }],
    }),
    updateAd: build.mutation({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/name/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Ads", id },
        { type: "Ads", id: "LIST" },
      ],
    }),
    approveAd: build.mutation({
      query: (id) => ({
        url: `${BASE_URL}/approve/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Ads", id: "LIST" }],
    }),
    getAdProgress: build.query({
      query: () => ({
        url: `${BASE_URL}/status/process`,
      }),
    }),
  }),
});

export const {
  useCreateAdMutation,
  useDeleteAdMutation,
  useDeleteAdsMutation,
  useCreateAdFromImageMutation,
  useAssignToPlaylistMutation,
  useUpdateAdMutation,
  useApproveAdMutation,
  useGetAdsQuery,
  useGetAdQuery,
  useGetAdProgressQuery,
} = adsApi;
