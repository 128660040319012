import { toast as originalToast } from "react-toastify";
import IconButton from "../ui/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const CloseButton = ({ closeToast }) => (
  <IconButton
    aria-label="close-toast"
    className="p-0 absolute top-4 right-4 text-base-300 w-8 h-8 box-content z-10"
    onClick={closeToast}
  >
    <CloseIcon />
  </IconButton>
);

const wrapToast = (fn) => (message, options) =>
  fn(message, {
    closeButton: CloseButton,
    className: "has-[button]:pr-[48px]",
    ...options,
  });

const toast = wrapToast(originalToast);

Object.entries(originalToast).forEach(([key, fn]) => {
  if (typeof fn === "function") {
    toast[key] = wrapToast(fn);
  }
});

export default toast;
