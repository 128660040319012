import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import loadImage from "blueimp-load-image";

import toast from "HOCs/toast";

import { renderComponent } from "utils/components";

import IconButton from "ui/IconButton";
import Skeleton from "ui/Skeleton";

import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import { dataURLtoFile } from "./helpers";

const UploadComponent = ({ getRootProps, text, file, onDelete }) => {
  if (file) {
    return (
      <div className="flex items-center justify-between bg-base-50 px-5 py-3 rounded-xl min-h-[80px]">
        <div className="flex gap-2 w-full max-w-screen-lg truncate">
          <VideoIcon className="shrink-0" />
          <span className="truncate">{file.name}</span>
        </div>
        <IconButton
          aria-label="delete"
          className="text-common-error"
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className="flex flex-col justify-center rounded-xl border border-dashed border-gray-200 px-6 py-4 min-h-[80px] cursor-pointer"
    >
      <div className="flex gap-4 items-center justify-center text-center">
        <UploadIcon className="w-4 shrink-0" />
        <span>{text}</span>
      </div>
    </div>
  );
};

const Dropzone = ({
  text = "Upload from device",
  initFile = null,
  Component = UploadComponent,
  onDrop = () => {},
  onDelete = () => {},
}) => {
  const [file, setFile] = useState(initFile);

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile.errors[0].code === "file-invalid-type") {
          toast.error("File must be an image (JPEG, PNG).");
        }
        return;
      }

      const file = acceptedFiles[0];

      // Add preview to file
      const previewUrl = URL.createObjectURL(file);
      setFile(
        Object.assign(file, {
          preview: previewUrl,
        })
      );

      // correcting orientation
      loadImage(
        file,
        (canvas) => {
          const imageDataUrl = canvas.toDataURL();

          const fixedFile = dataURLtoFile(imageDataUrl, file.name);

          onDrop([fixedFile]);
        },
        {
          orientation: true,
          canvas: true,
        }
      );
    },
    [onDrop]
  );

  const handleDelete = () => {
    setFile(null);
    onDelete();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  const { accept, ...inputProps } = getInputProps();

  return (
    <>
      <input {...inputProps} accept={accept + ";capture=camera"} />
      {renderComponent(
        Component({ getRootProps, file, text, onDelete: handleDelete })
      )}
    </>
  );
};

Dropzone.Skeleton = () => <Skeleton.Round />;

export default Dropzone;
