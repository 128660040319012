const dayjs = require("dayjs");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const isoDateFormatPattern = new RegExp(
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/
);

export const isIsoDate = (date) => isoDateFormatPattern.test(date);

export const formatDate = (
  date = Date.now(),
  options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    locales: "en-US",
  }
) => {
  const { locales = "en-US", ...optionProps } = options;

  if (date instanceof Date) {
    return date.toLocaleDateString(locales, optionProps);
  }

  if (typeof date === "number") {
    return new Date(date).toLocaleDateString(locales, optionProps);
  }

  throw new Error("Invalid date format");
};

export function msToSec(ms = 0) {
  const totalSeconds = Math.ceil(ms / 1000);

  return totalSeconds % 60;
}

export function formatMilliseconds(ms = 0) {
  const totalSeconds = Math.ceil(ms / 1000);

  const minutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

export const msToTimeFormat = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours} h `;
  }

  if (minutes > 0 || hours > 0) {
    formattedTime += `${remainingMinutes} min `;
  }

  if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
    formattedTime += `${remainingSeconds} sec`;
  }

  return formattedTime.trim();
};

export function formatTime(input, format = "hh:mm A") {
  if (!input) return input;

  const time = dayjs(input, ["HH:mm", "hh:mm A"], true);

  if (!time.isValid()) {
    throw new Error("Invalid time format");
  }

  return time.format(format);
}
window.formatTime = formatTime;
