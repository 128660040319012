// todo rename file to products.js
import { DAYS_MAP } from "pages/AdsCreate/helpers";
import { formatTime } from "./date";

export const getPrice = (originalPrice, oldPrice, discountPercentage = 0) => {
  if (originalPrice) {
    return parseFloat(originalPrice.toFixed(2)) || undefined;
  }

  const discountDecimal = discountPercentage / 100;
  const discountAmount = oldPrice * discountDecimal;

  return parseFloat((oldPrice - discountAmount).toFixed(2)) || undefined;
};

const extractVolume = (variant) => {
  if (!variant) return null;

  if (typeof variant === "number") {
    return variant;
  }

  const lowerCaseVariant = variant.toLowerCase();
  const match = lowerCaseVariant.match(/[\d.]+(?:l|ml)/);

  return match ? match[0] : null;
};

export const getDefaultPlaylistFields = (products, templateId) =>
  products.map(
    ({ brand, discount = 0, img, name, price, variant, category }, i) => ({
      template_id: templateId,
      name: `product${i + 1}`,
      data: {
        brand,
        img,
        name,
        // todo remove TITLE field
        title: name,
        volume: extractVolume(variant),
        discountPercentage: discount,
        // todo move to func
        price: price - price * (discount / 100),
        category,
      },
    })
  );

const getProductFields = (
  product = {},
  { price, oldPrice, discountPercentage }
) => {
  const { displayName, name, img, variant, brand, category, bsImage } = product;

  return {
    brand: brand?.name,
    volume: extractVolume(variant),
    name: displayName ?? name,
    // todo remove TITLE field
    title: displayName ?? name,
    oldPrice,
    discountPercentage,
    price: getPrice(price, oldPrice, discountPercentage),
    category,
    img: bsImage?.cdnUrl ?? img,
  };
};

export const templateDataAdapter = (obj, config = {}) => {
  if (Object.keys(obj?.workingHours || {}).length > 0) {
    obj.workingHours = Object.entries(obj.workingHours)
      .filter(([_, dayData]) => dayData?.enabled)
      .map(([dayId, dayData]) => ({
        id: dayId,
        label: DAYS_MAP[dayId].label,
        from: formatTime(dayData.from, config?.workingHoursFormat),
        to: formatTime(dayData.to, config?.workingHoursFormat),
      }));
  }

  if (obj.product) {
    const { product, oldPrice, price, discountPercentage, ...rest } = obj;

    const productFields = getProductFields(product, {
      price,
      oldPrice,
      discountPercentage,
    });

    return {
      ...rest,
      ...productFields,
    };
  }

  if (obj.products) {
    const { products, oldPrice, price, discountPercentage, ...rest } = obj;
    return {
      ...rest,
      oldPrice,
      price: getPrice(price, oldPrice, discountPercentage),
      products: Object.fromEntries(
        Object.entries(products).map(([key, value]) => {
          const { product, price, oldPrice, discountPercentage, ...rest } =
            value;
          const productFields = getProductFields(product, {
            price,
            oldPrice,
            discountPercentage,
          });

          return [
            key,
            {
              ...rest,
              ...productFields,
            },
          ];
        })
      ),
    };
  }

  return obj;
};
