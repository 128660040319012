import { useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";
import cx from "classnames";

import {
  useGetTemplateFiltersQuery,
  useGetTemplatesQuery,
} from "services/templates";
import { showDrawer } from "store/ducks/ui/drawer";

import useDebounce from "hooks/useDebounce";
import useSearch from "hooks/useSearch";
import useFilters from "hooks/useFilter";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";

import SearchInput from "components/SearchInput";
import MediaCard from "components/MediaCard";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";

import { FILTER } from "constants/drawer";

import styles from "./styles.module.scss";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const TemplatesCatalog = ({ headerProps, onSelect = () => {} }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch("");
  const debouncedSearch = useDebounce(700);

  const { data: filters = [] } = useGetTemplateFiltersQuery();

  const { urlFilters, filterCount, isActiveFilter } = useFilters(filters);

  const { data: { data: templatesData = [], total } = {}, isFetching } =
    useGetTemplatesQuery({
      page,
      limit: PAGINATION.limit,
      search,
      categories: urlFilters?.categories?.join(","),
      productsCount: urlFilters?.products_count?.join(","),
      useDefault: true,
    });

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  const handleOpenFilter = () => {
    dispatch(
      showDrawer({
        content: FILTER,
        base: true,
        headerProps: {
          title: "Filter",
        },
        drawerProps: {
          PaperProps: {
            className: "min-h-[50%]",
          },
        },
        data: {
          filters,
        },
      })
    );
  };

  return (
    <CommonTemplate headerProps={headerProps}>
      <ComponentFallbackRenderer data={templatesData} isLoading={isFetching}>
        {(templates) => (
          <InfiniteScroll
            next={handleMoreData}
            dataLength={templates.length}
            hasMore={page * PAGINATION.limit < total}
            scrollThreshold={0.55}
            loader={null}
          >
            <div className="grid grid-cols-2 gap-x-2 gap-y-4 px-4 py-6">
              {(isFetching && !templates.length
                ? Array.from({ length: 6 }, () => ({ uuid: uuidv4() }))
                : templates
              ).map(
                ({ uuid, id = uuid, name, products_count, preview_url }) => (
                  <SkeletonWrapper
                    key={id}
                    condition={isFetching && !templates.length}
                    Component={MediaCard.Skeleton}
                  >
                    <div className="flex flex-col relative">
                      <MediaCard
                        title={name}
                        img={preview_url}
                        className="items-start"
                        aspectRatio="auto"
                        imageClassName="aspect-[9/16]"
                        Description={
                          <span className="text-base-500 text-sm">
                            Products: {products_count || 1}
                          </span>
                        }
                        component={Button}
                        componentProps={{
                          onClick: (e) => {
                            onSelect(e, {
                              id,
                              title: name,
                              count: products_count,
                              img: preview_url,
                            });
                          },
                        }}
                      />
                    </div>
                  </SkeletonWrapper>
                )
              )}
            </div>
          </InfiniteScroll>
        )}
      </ComponentFallbackRenderer>
    </CommonTemplate>
  );
};

export default TemplatesCatalog;
