import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import toast from "HOCs/toast";

import pages from "router/links";
import { useGetStoresQuery } from "services/stores";
import { useAssignDevicesToPlaylistMutation } from "services/playlists";

import CommonTemplate from "templates/CommonTemplate";

import Section from "components/Section";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

import Card from "pages/AssignScreens/components/Card";

import { DEVICES_NAME, IS_CONNECT_NAME, IS_PLAY_NAME } from "./helpers";
import { NOTIFICATION_MESSAGES } from "utils/notificationMessages";

const AssignScreens = ({
  headerProps = { title: "Assign to Screen" },
  playlistId: playlistIdFromProps,
}) => {
  const navigate = useNavigate();
  const { playlistId: playlistIdParams } = useParams();
  const playlistId = playlistIdParams ?? playlistIdFromProps;

  const { data: { data: storesData = [] } = {}, isFetching } =
    useGetStoresQuery({
      playlistId,
    });
  const [assignDevices, { isLoading }] = useAssignDevicesToPlaylistMutation();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      devices: [],
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: DEVICES_NAME,
  });

  useEffect(() => {
    if (!isFetching) {
      const initialDevices = storesData.flatMap(({ devices }) =>
        devices.map(({ id, active_customer_playlist, is_assign }) => ({
          device_id: id,
          [IS_PLAY_NAME]: active_customer_playlist === Number(playlistId),
          [IS_CONNECT_NAME]: is_assign,
        }))
      );
      replace(initialDevices);
      // if u need return init values uncomment this line [`reset` from UseForm]
      // reset({ [DEVICES_NAME]: initialDevices });
    }
  }, [storesData, isFetching]);

  const onSubmit = (data) => {
    assignDevices({ playlistId, data })
      .unwrap()
      .then(() => {
        toast.success("Your Playlist is ready!");
        navigate(pages.playlists.path);
      })
      .catch(() => {
        toast.error(NOTIFICATION_MESSAGES.ERROR);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CommonTemplate headerProps={headerProps}>
        <ComponentFallbackRenderer data={storesData} isLoading={isFetching}>
          {(stores) => (
            <div className="flex flex-col flex-1 gap-4 justify-between px-4 py-6">
              <div className="flex flex-col gap-7">
                {(isFetching && !stores.length
                  ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
                  : stores
                ).map(({ uuid, id = uuid, name, devices = [] }) => (
                  <SkeletonWrapper
                    key={id}
                    condition={isFetching && !stores.length}
                    Component={AssignScreens.Skeleton}
                  >
                    <Section
                      key={id}
                      title={name}
                      titleProps={{
                        size: "xl",
                        className: "font-normal",
                      }}
                    >
                      <div className="flex flex-col gap-2">
                        {devices.map(
                          ({ uuid, id = uuid, name: deviceName }) => {
                            const deviceIndex = fields.findIndex(
                              (field) => field.device_id === id
                            );

                            if (deviceIndex === -1) return null;

                            return (
                              <Card
                                key={id}
                                index={deviceIndex}
                                title={deviceName}
                                control={control}
                                setValue={setValue}
                              />
                            );
                          }
                        )}
                      </div>
                    </Section>
                  </SkeletonWrapper>
                ))}
              </div>
              <Button.Loading
                type="submit"
                variant="contained"
                size="small"
                className="sticky bottom-4"
                disabled={isFetching}
                loading={isLoading}
              >
                Save
              </Button.Loading>
            </div>
          )}
        </ComponentFallbackRenderer>
      </CommonTemplate>
    </form>
  );
};

AssignScreens.Skeleton = () => (
  <div className="flex flex-col gap-4">
    <Skeleton.Round
      sx={{
        width: "35%",
        height: 28,
      }}
    />
    {Array.from({ length: 3 }, () => ({ uuid: uuidv4() })).map(({ uuid }) => (
      <Skeleton.Round
        key={uuid}
        width="100%"
        height={64}
        sx={{ fontSize: "1rem" }}
      />
    ))}
  </div>
);

export default AssignScreens;
