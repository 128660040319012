import { useNavigate } from "react-router-dom";
import cx from "classnames";

import { PRODUCT_VIEWS } from "constants/index";
import { PRODUCT_FIELDS } from "constants/product";
import { VIEW_MODE } from "constants/searchParams";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

import ProductSelectActions from "components/ProductSelectActions";

import {
  renderMonoFields,
  renderMultiFields,
  STEPS,
  WorkingHours,
} from "pages/AdsCreate/helpers";

import { getPrice } from "utils/templates";
import { hasProperties } from "utils/index";

import Title from "components/Title";

const renderFields = (
  { register, unregister, errors, setValue, getValues, resetField, trigger },
  { properties, definitions, isProducts, isProductRequired, onScan, onSelect }
) => (
  <div className="flex flex-col">
    {/*label FIELDS FOR MULTIPRODUCTS*/}
    {renderMultiFields(
      { properties, definitions },
      { register, errors, setValue, getValues, resetField, trigger },
      ProductSelectActions,
      { onScan, onSelect }
    )}

    {/*/label COMMON FIELDS FOR ANY PRODUCT*/}
    <div
      className={cx("flex flex-col gap-4 border-base-100", {
        "border-t pt-6": isProducts,
      })}
    >
      {renderMonoFields(
        { properties, isProducts, isProductRequired },
        {
          register,
          unregister,
          errors,
          setValue,
          getValues,
          resetField,
          trigger,
        },
        ProductSelectActions,
        { onScan, onSelect }
      )}
    </div>
  </div>
);

// label COMPONENT PRODUCTS_FORM
const ProductsForm = ({
  control,
  register,
  unregister,
  errors,
  setValue,
  getValues,
  resetField,
  trigger,
  watch,
  headerProps,
  setCurrentProduct,
  setStep,
  isLoadingSchema,
  schema = {},
  preview,
}) => {
  const navigate = useNavigate();
  const {
    definitions,
    properties,
    isProductRequired = true,
    isSingleWorkingHours = false,
    isAllWorkingHours = false,
    workingHours = [],
  } = schema;
  const isProducts = !!properties?.products;
  const isWorkingHours = !!workingHours.length;

  const watchPrice = watch(PRODUCT_FIELDS.OLD_PRICE);
  const watchDiscount = watch(PRODUCT_FIELDS.DISCOUNT_PERCENTAGE);
  const newPrice = getPrice(null, watchPrice, watchDiscount) ?? 0;

  const isShowPrice = hasProperties(properties, [
    PRODUCT_FIELDS.OLD_PRICE,
    PRODUCT_FIELDS.DISCOUNT_PERCENTAGE,
  ]);

  const handleSelectProduct =
    (view = PRODUCT_VIEWS.CATALOG) =>
    (e, key) => {
      setStep(STEPS.PRODUCTS_CATALOG);
      if (key) {
        setCurrentProduct(key);
      }

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(VIEW_MODE, view);
      navigate(
        {
          pathname: window.location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    };

  if (isLoadingSchema) {
    return <ProductsForm.Skeleton />;
  }

  return (
    <CommonTemplate
      headerProps={{
        className: "bg-base-50",
        ...headerProps,
      }}
    >
      <div className="flex flex-col gap-4 pb-fixed-btn-space-4">
        {/*label TEMPLATE VIEW*/}
        <div className="bg-base-50 rounded-es-lg rounded-ee-lg px-4 pb-6">
          <img
            src={preview}
            alt="template"
            className="w-full object-cover rounded-lg aspect-[9/16]"
          />
        </div>
        {/*label CONTENT*/}
        <div className="flex flex-col px-4 pb-6">
          {renderFields(
            {
              register,
              unregister,
              errors,
              setValue,
              getValues,
              resetField,
              trigger,
            },
            {
              properties,
              definitions,
              isProducts,
              isProductRequired,
              onScan: handleSelectProduct(PRODUCT_VIEWS.SCANNER),
              onSelect: handleSelectProduct(PRODUCT_VIEWS.CATALOG),
            }
          )}
          {(isSingleWorkingHours || isAllWorkingHours) &&
            WorkingHours(
              { workingHours, isSingleWorkingHours, isAllWorkingHours },
              {
                control,
                register,
                unregister,
                errors,
                setValue,
                getValues,
                resetField,
                trigger,
                watch,
              }
            )}
          {isShowPrice && (
            <div className="flex justify-between py-6 border-t-[1px] border-base-100 -mx-4 p-4 mt-4">
              <div className="flex flex-col gap-1">
                <span className="text-base-1000 text-sm font-semibold leading-none">
                  New Price
                </span>
                <span className="text-base-500 text-sm leading-none">
                  Price will adjust automatically
                </span>
              </div>
              <Title size="2xl" className="flex gap-1 text-nowrap">
                $ {newPrice}
              </Title>
            </div>
          )}
        </div>
        <div className="fixed bottom-safe-bottom-4 container px-4 z-10">
          <Button variant="contained" fullWidth type="submit">
            Next
          </Button>
        </div>
      </div>
    </CommonTemplate>
  );
};

ProductsForm.Skeleton = () => (
  <CommonTemplate
    headerProps={{
      className: "bg-base-50",
    }}
  >
    <div className="flex flex-1 flex-col gap-4 pb-24">
      <div className="bg-base-50 rounded-es-lg rounded-ee-lg -mx-4 -mt-6 px-14 pb-12">
        <Skeleton.Round
          width="100%"
          sx={{ aspectRatio: "9 / 16", height: "auto" }}
        />
      </div>
      <div className="flex flex-col gap-4 px-4 pb-6">
        {Array(5)
          .fill(null)
          .map((_, i) => (
            <Skeleton.Round key={i} height={64}></Skeleton.Round>
          ))}
      </div>
    </div>
  </CommonTemplate>
);

export default ProductsForm;
