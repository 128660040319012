import { renderComponent } from "utils/components";

import EmptyData from "components/EmptyData";

const ComponentFallbackRenderer = ({
  data = [],
  isLoading = false,
  children,
  alt = EmptyData,
}) => {
  if (!data.length && !isLoading) {
    return renderComponent(alt, children);
  }

  return children(data);
};

export default ComponentFallbackRenderer;
