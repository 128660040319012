import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useGetStoreQuery } from "services/stores";

import BaseTemplate from "templates/BaseTemplate";
import CommonTemplate from "templates/CommonTemplate";
import Playlist from "components/Playlist";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import { PAGE_TYPES } from "constants/index";
import { plural } from "utils";

const Store = () => {
  const { storeId, pageType } = useParams();
  const {
    data: storeData = {},
    isFetching,
    refetch,
  } = useGetStoreQuery(storeId);

  const { name, address, devices = [] } = storeData;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const WrapperComponent =
    pageType === PAGE_TYPES.MAIN ? BaseTemplate : CommonTemplate;

  return (
    <WrapperComponent
      headerProps={{
        title: name,
        description: address,
      }}
    >
      <ComponentFallbackRenderer data={devices} isLoading={isFetching}>
        {(devices) => (
          <div className="px-4 py-3">
            {(isFetching && !devices.length
              ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
              : devices
            ).map(
              ({
                uuid,
                id = uuid,
                name,
                playlists: { data: playlistsData = [], active } = {},
                label,
              }) => {
                const playlistCount = playlistsData.length;
                const showPlaylist = active ?? playlistsData[0];

                return (
                  <SkeletonWrapper
                    key={id}
                    condition={isFetching && !devices.length}
                    Component={Playlist.Skeleton}
                  >
                    <Playlist
                      isReversed
                      label={!!active && "Active"}
                      name={name}
                      videos={showPlaylist?.previews}
                      component={NavLink}
                      to={`screens/${id}`}
                      footerRightContent={
                        <span className="text-base-500">
                          ({playlistCount} {plural("Playlist", playlistCount)})
                        </span>
                      }
                    />
                  </SkeletonWrapper>
                );
              }
            )}
          </div>
        )}
      </ComponentFallbackRenderer>
    </WrapperComponent>
  );
};

export default Store;
